<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style scoped>
@import "./font-style/font.css";
body {
  font-family: PingFang SC;
}
</style>
