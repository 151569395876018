// 判断是否浏览过
export default {
  isView(view) {
    // console.log(4567889);
    console.log(JSON.parse(sessionStorage.getItem("viewHistoryList")));
    var isView = false;
    isView =
      JSON.parse(sessionStorage.getItem("viewHistoryList")).indexOf(view) == -1
        ? (isView = false)
        : (isView = true);
    return isView;
  },
};
