import Vue from "vue";
// import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/icons";
// import moment from "moment";
// import "moment/locale/zh-cn";
import "../src/less/index.less";
import VueLazyload from "vue-lazyload";
import utils from "./utils/utils";
Vue.use(VueLazyload);
Vue.prototype.$utils = utils;
// Vue.use(window.VueAwesomeSwiper);
// Vue.use(ElementUI);

Vue.config.devtools = true;
Vue.config.productionTip = false;
// // 屏蔽错误信息
// Vue.config.errorHandler = (err) => {
//   console.log(err);
// };
// // 屏蔽警告信息
// Vue.config.warnHandler = (message) => {
//   console.log(message);
// };
// 配置项
Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: "dist/error.png",
  // loading: "dist/loading.gif",
  attempt: 1,
});
//页面跳转显示在顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
