import Vue from "vue";
import Vuex from "vuex";
import {
  getItem,
  setItem,
  removeStorage,
  setlocalItem,
  getlocalItem,
  getsessionItem,
  removelocalStorage,
} from "@/utils/storage";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    flag: true,
    token: getItem("token"), //token
    userInfo: getItem("userInfo"), //用户信息
    activateDialogVisible: false,
    value: "",
    searchHistoryList: getlocalItem("searchHistoryList") || [], //存储搜索历史记录
    browseHistoryList: getlocalItem("browseHistoryList") || [], //存储浏览历史记录
    viewHistoryList: getsessionItem("viewHistoryList") || [], //存储浏览过的记录
  },
  getters: {},
  mutations: {
    setToken(state, val) {
      state.token = val;
      setItem("token", val);
    },
    userInfo(state, val) {
      state.userInfo = val;
      setItem("userInfo", val);
    },
    removeToken(state) {
      state.token = "";
      removeStorage("token");
      state.userInfo = "";
      removeStorage("userInfo");
    },
    changeValue: (state, value) => {
      state.value = value;
    },
    removeCache(state) {
      console.log(123);
      state.searchHistoryList = [];
      state.browseHistoryList = [];
      removelocalStorage();
    },
    switch(state, val) {
      state.activateDialogVisible = val;
    },
    modifyFlag(state, val) {
      state.flag = val;
    },
    // modifytitle: (state, val) => {
    //   state.title = val;
    //   console.log(state.title);
    // },
  },
  actions: {},
  modules: {},
});
