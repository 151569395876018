import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/app",
    component: () => import("@/views/app.vue"),
  },
  {
    path: "/",
    redirect: "/home",
    // name: "home",
    component: () => import("@/components/NavBar.vue"),
    children: [
      {
        path: "home",
        // name: "home",
        component: () => import("@/views/HomePage.vue"),
      },
      {
        path: "about",
        // name: "about",
        component: () => import("@/views/AboutUs.vue"),
      },
      {
        path: "bidding",
        // name: "bidding",
        component: () => import("@/views/BiddingInformation.vue"),
      },
      {
        path: "news",
        // name: "news",
        component: () => import("@/views/NewsInformation.vue"),
      },
      {
        path: "detail", //三级
        // name: "detail",
        component: () => import("@/views/newsDetail.vue"),
      },
      {
        path: "software",
        // name: "software",
        component: () => import("@/views/SoftwareDownload.vue"),
      },
      {
        // 中标查询
        path: "bid",
        component: () => import("@/views/bidDetails.vue"),
      },
    ],
  },
  {
    path: "/my",
    component: () => import("@/components/asideNav.vue"),
    children: [
      {
        path: "FollowUnits",
        component: () => import("@/views/user/FollowUnits.vue"),
      },
      {
        path: "ProjectCollection",
        component: () => import("@/views/user/ProjectCollection.vue"),
      },
      {
        path: "Subscriptions",
        component: () => import("@/views/user/Subscriptions.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// 注册一个全局前置守卫
router.beforeEach((to, from, next) => {
  console.log("前置", to);
  if (
    to.path === "/my/Subscriptions" ||
    to.path === "/my/FollowUnits" ||
    to.path === "/my/ProjectCollection"
  ) {
    // 存在token
    if (store.state.token) {
      next();
    } else {
      console.log("主页");
      next("/");
    }
  } else {
    next();
  }
});
export default router;
