import Cookies from "js-cookie";

// Cook
//来封装存缓存的方法
export const setItem = (key, value) => {
  if (typeof value == "object") {
    Cookies.set(key, JSON.stringify(value));
  } else {
    Cookies.set(key, value);
  }
};
//封装读缓存的方法
export const getItem = (key) => {
  try {
    return JSON.parse(Cookies.get(key));
  } catch (error) {
    return Cookies.get(key);
  }
};
//清除缓存
export const removeStorage = (key) => {
  Cookies.remove(key);
};

// localStorage
//封装存缓存的方法
export const setlocalItem = (key, value) => {
  if (typeof value == "object") {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};
//封装读缓存的方法
export const getlocalItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return localStorage.getItem(key);
  }
};
// 清空缓存
export const removelocalStorage = () => {
  localStorage.clear();
  localStorage.removeItem("searchHistoryList");
  console.log(
    localStorage.getItem("browseHistoryList"),
    localStorage.getItem("searchHistoryList")
  );
};

// sessionStorage
//封装存缓存的方法
export const setsessionItem = (key, value) => {
  if (typeof value == "object") {
    sessionStorage.setItem(key, JSON.stringify(value));
  } else {
    sessionStorage.setItem(key, value);
  }
};
//封装读缓存的方法
export const getsessionItem = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (error) {
    return sessionStorage.getItem(key);
  }
};
// 清空缓存
export const removesessionStorage = () => {
  sessionStorage.clear();
  // localStorage.removeItem("searchHistoryList");
  // console.log(
  //   localStorage.getItem("browseHistoryList"),
  //   localStorage.getItem("searchHistoryList")
  // );
};
